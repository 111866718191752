/* This example requires Tailwind CSS v2.0+ */
import React from "react"
//import { StaticImage } from "gatsby-plugin-image"
import KdpImage from "../images/demarche-qualite.jpg"



export default function Example() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">

        <svg
          className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 bg bg-[url('../images/ceos-pattern.png')] grayscale brightness-200 opacity-20"
          width={404}
          fill="none"
          viewBox="0 0 404 4000"
          aria-hidden="true"
        >
        </svg>

        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">

            <div className="relative sm:py-16 lg:py-0">
              <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
                <div className="absolute inset-y-0 right-1/2 w-full bg-gray-100 rounded-r-3xl lg:right-72" />

              </div>
              <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">

                <div className="relative pt-64 pb-20 rounded-2xl shadow-xl overflow-hidden">
                  <img
                    className="absolute inset-0 h-full h-auto object-cover"
                    src={KdpImage}
                    width={560}
                    alt="Démarche Qualité"
                  />
                  <div className="relative px-8">

                    <blockquote className="mt-8">
                      <div className="relative text-lg font-medium text-white md:flex-grow">

                        <p className="relative text-justify">

                        </p>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">


              {/* Content area */}
              <div className="pt-12 sm:pt-16 lg:pt-20">



                <h1 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                  Démarche qualité
                </h1>

                <div className="mt-6 text-gray-500 space-y-6">
                  <h3 className="text-1xl font-extrabold text-gray-900 tracking-tight sm:text-2xl">Accessibilité et disponibilité</h3>
                  <p className="text-base leading-7 text-justify">
                    Tels sont les maîtres mots qui caractérisent la relation nouée par le Cabinet Céos Avocats avec ses interlocuteurs par une information régulière et la disponibilité de son équipe.
                  </p>
                  <h3 className="text-1xl font-extrabold text-gray-900 tracking-tight sm:text-2xl">Engagement qualité</h3>
                  <p className="text-base leading-7 text-justify">

                    Le Cabinet Céos Avocats est soucieux d’apporter une attention toute particulière tant à sa relation avec les clients qu’à la qualité de ses préconisations et du travail accompli, dans le respect des règles déontologiques de la profession d’avocat.
                    Cet engagement qualité se traduit par une actualisation permanente de l’expertise des membres de son équipe et le respect d’une charte de fonctionnement interne.
                    Sa maîtrise des nouvelles technologies est également déterminante de cet engagement.

                  </p>
                  <h3 className="text-1xl font-extrabold text-gray-900 tracking-tight sm:text-2xl">Transparence et validation client</h3>
                  <p className="text-base leading-7 text-justify">
                    Préalablement à toute démarche, le cabinet Céos Avocats recherche l’approbation de son client sur les projets d’actes qu’il rédige et les modalités de son action.
                  </p>
                </div>
              </div>


            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

