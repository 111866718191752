import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpAriane from "../blocs/Ariane"

import KdpDemarche from "../blocs/DemarcheQualite"

const ariane = [
  { name: 'Démarche qualité', href: '', current: true },
]

export default function DemarcheQualite() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Démarche qualité de CEOS Avocats</title>
        <meta name="description" content="Accessibilité et disponibilité, engagement qualité, transparence et validation client" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        



        <KdpDemarche />




      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}